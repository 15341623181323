import { Text } from "@mantine/core"
import { dissoc } from "ramda"
import dayjs from "dayjs"
import { Checkbox, DatePicker, Create, Form, Input, Select, useForm } from "@pankod/refine"
import { IDeal } from "interfaces"
import { DropZone } from "components/Dropzone.component"
import { CHAIN_OPTIONS } from "./chain"

export const DealCreate = () => {
  const { formProps, saveButtonProps } = useForm<IDeal>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        onFinish={async (values: any) => {
          const dealSize = values.isIdo
            ? values.saftSize
            : Number(values.saftSize) / (1 - Number(values.feePercentage) / 100)
          return (
            formProps.onFinish && formProps.onFinish({ ...dissoc("saftSize", values), dealSize })
          )
        }}
      >
        <Form.Item label="Project Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="IDO?"
          name="isIdo"
          rules={[{ required: true }]}
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox />
        </Form.Item>

        <Form.Item label="About" name="about" rules={[{ required: true }]}>
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item label="Price" name="weightedAveragePrice" rules={[{ required: true }]}>
          <Input placeholder="$0.42" />
        </Form.Item>

        <Form.Item label="Vesting schedule" name="vestingSchedule" rules={[{ required: true }]}>
          <Input placeholder="10% TGE then 8% every 3 weeks" />
        </Form.Item>

        <Form.Item label="Discord invite URL" name="discordInviteUrl" rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.Item label="SAFT size ($)" name="saftSize" rules={[{ required: true }]}>
          <Input placeholder="100000" />
        </Form.Item>

        <Form.Item
          label="Fee (%)"
          name="feePercentage"
          rules={[{ required: true }]}
          style={{ marginBottom: 0 }}
        >
          <Input placeholder="10" />
        </Form.Item>
        <Text>
          We now collect fees for both IDOs and private deals. For private deals we take the fee
          from the users and for IDOs we take it from the project
        </Text>
        <Text>
          The typical private deal fee is 10% and IDO fee is 7.5% however this may vary. If you
          aren't 100% sure what the fee percentage is, stop and ask Alex or Imran. DO NOT guess
          this.
        </Text>

        <Form.Item
          label="Minimum allocation ($)"
          name="minAllocation"
          rules={[{ required: true }]}
          style={{ marginTop: 16 }}
        >
          <Input placeholder="50" />
        </Form.Item>

        <Form.Item label="Logo url" name="logoUrl" rules={[{ required: true }]}>
          <DropZone />
        </Form.Item>

        <Form.Item
          label="Chain"
          name="chain"
          rules={[{ required: true }]}
          style={{ display: "inline-block", width: "500px" }}
        >
          <Select options={CHAIN_OPTIONS} />
        </Form.Item>
        <Text>Contact Naz on Discord if the desired chain is not listed.</Text>

        <Form.Item
          label="Project telegram url"
          name="projectTelegramUrl"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project twitter url"
          name="projectTwitterUrl"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project website url"
          name="projectWebsiteUrl"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project whitepaper url"
          name="projectWhitepaperUrl"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project discord url"
          name="projectDiscordUrl"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Desired start time (in your local time)"
          getValueProps={(value) => {
            return {
              value: value ? dayjs(value) : "",
            }
          }}
          name="desiredStartTime"
          style={{ margin: 0 }}
          rules={[
            { required: false },
            ({ isFieldTouched }) => ({
              validator: async (rule, value) => {
                if (!isFieldTouched("desiredStartTime")) {
                  return
                }
                const isPast = dayjs().isAfter(value)
                if (isPast) {
                  throw new Error("Start time cannot be in the past")
                }
              },
            }),
          ]}
        >
          <DatePicker showTime={{ format: "HH:mm" }} />
        </Form.Item>

        <Text mt="md">
          If you are having issues Creating the deal please{" "}
          <a
            href="https://www.loom.com/share/1554f1d748da49ed99dc7cb0a6dcebff"
            target="_blank"
            rel="noreferrer"
          >
            see this video
          </a>{" "}
          on how to fix.
        </Text>
      </Form>
    </Create>
  )
}
