import { getFastContract } from "utils/contract"
import { BigNumber } from "ethers"
import { Contract } from "ethers"
import { useQuery } from "react-query"
import { IDeal } from "interfaces"
import { useIsUncapped } from "./dealQueries"

// This must be the current deal controller being used by deals on the backend
const CURRENT_DEAL_CONTROLLER = "0x192Ea288Eac90a6c79fF6027378B75379c8e0904"
// This must be the address of a wallet that has broker status
const BROKER_ADDRESS = "0x9044b7e45EaBD1cA456aC5072d12a7e258cd27F1"

const abi = [
  "function baseCoefficient() external view returns (uint256)",
  "function postGracePeriodMaxContribution(address _wallet, uint256 _dealSize) public view returns (uint256)",
]

function getDealControllerContract(): Contract {
  return getFastContract(CURRENT_DEAL_CONTROLLER, abi)
}

export const useBaseCoefficient = () => {
  return useQuery<BigNumber>(
    [
      {
        provider: "web3",
        variable: "baseCoefficient",
        contract: CURRENT_DEAL_CONTROLLER,
      },
    ],
    async () => {
      const contract = getDealControllerContract()

      return (await contract.baseCoefficient()).toNumber()
    }
  )
}

export const usePeddlerAllocation = (deal: IDeal) => {
  const { data: isUncapped, isLoading } = useIsUncapped(deal.contractAddress)

  return useQuery<string>(
    [
      {
        provider: "web3",
        variable: "brokerBaseAllocation",
        dealContractAddress: deal.contractAddress,
        contract: CURRENT_DEAL_CONTROLLER,
      },
    ],
    async () => {
      if (isUncapped) {
        return "Uncapped"
      }
      const contract = getDealControllerContract()

      const allocation = await contract.postGracePeriodMaxContribution(
        BROKER_ADDRESS,
        deal.dealSize
      )

      return allocation.div(10).toFixed(0)
    },
    {
      enabled: !deal.ended && !isLoading,
    }
  )
}
