import invariant from "tiny-invariant"
import { useWeb3React } from "@web3-react/core"
import { useV1SaleContract } from "./useSaleContract.hook"
import { useMutation } from "react-query"

export const useWithdraw = (contractAddress: string) => {
  const { account } = useWeb3React()
  const contract = useV1SaleContract(contractAddress)

  return useMutation(
    async (saleId: number) => {
      invariant(account, "Account not given")
      invariant(contract, "Contract not given")

      const sale = await contract.saleInfo(saleId)
      const remaining = sale.amountBusdFunded.sub(sale.amountBusdWithdrawn)

      const tx = await contract.withdraw(saleId, remaining)
      const receipt = await tx.wait()
      if (receipt.status !== 1) {
        throw new Error("Transaction failed")
      }
      return
    },
    {
      onError: (err: any) => {
        const message = err?.data?.message || err?.message || err
        console.error(message)
      },
    }
  )
}
