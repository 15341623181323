import React from "react"
import { useClientsContext } from "clients.context"
import FileDownload from "js-file-download"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import { EndDeal } from "./EndDeal.component"
import { Uncap } from "./Uncap.component"
import { formatNumber } from "utils/number"
import { snipAddress } from "utils/format"
import {
  DeleteButton,
  Button,
  Space,
  DateField,
  EditButton,
  BooleanField,
  List,
  Table,
  TextField,
  useTable,
  useApiUrl,
  message,
} from "@pankod/refine"
import { IDeal } from "interfaces"
import { WithdrawButton } from "./WithdrawButton.component"
import { BaseAllocation } from "./BaseAllocation.component"
import { PeddlerAllocation } from "./PeddlerAllocation.component"

export const DealList = () => {
  const { axios } = useClientsContext()
  const apiUrl = useApiUrl()
  const { tableProps } = useTable<IDeal>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
  })

  return (
    <>
      <List canCreate>
        <BaseAllocation />
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="name"
            key="name"
            title="Name"
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex="contractAddress"
            key="contractAddress"
            title="Contract address"
            render={(value) => (
              <TextField
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(value)
                  message.info(`${value} copied to clipboard`)
                }}
                value={snipAddress(value)}
              />
            )}
          />

          <Table.Column
            dataIndex="isIdo"
            key="isIdo"
            title="IDO?"
            render={(value) => <BooleanField value={value} falseIcon={<></>} />}
          />

          <Table.Column
            dataIndex="totalRaised"
            key="totalRaised"
            title="Raised"
            render={(value) => <TextField value={formatNumber(value.toBigNumber(0).toFixed(0))} />}
          />

          <Table.Column
            dataIndex="dealSize"
            key="dealSize"
            title="Deal Size"
            render={(value) => <TextField value={formatNumber(value.toBigNumber(0).toFixed(0))} />}
          />

          <Table.Column
            dataIndex="minAllocation"
            key="minAllocation"
            title="Min Alloc"
            render={(value) => <TextField value={value.toBigNumber(0).toFixed(0)} />}
          />

          <Table.Column
            key="peddlerAllocation"
            title="Peddler Alloc"
            render={(value, record: IDeal) => <PeddlerAllocation deal={record} />}
          />

          <Table.Column
            dataIndex="chain"
            key="chain"
            title="Chain"
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex="desiredStartTime"
            key="desiredStartTime"
            title="Start Time"
            render={(value, record: IDeal) => {
              if (record.ended) {
                return <TextField value="Ended" />
              }
              if (record.started) {
                return <TextField value="Started" />
              }
              if (!value) {
                return <TextField value="Not specified" />
              }

              return (
                <>
                  <DateField format="LLL" value={value} />
                  <br />
                  (in <TextField value={formatDistanceToNowStrict(new Date(value))} />)
                </>
              )
            }}
          />

          <Table.Column<IDeal>
            title="Actions"
            dataIndex="actions"
            render={(_text, record): React.ReactNode => (
              <Space>
                <EditButton size="small" recordItemId={record.id} hideText />
                <EndDeal deal={record} />
                <Uncap deal={record} />

                {record.ended && (
                  <Button
                    style={{ margin: 0 }}
                    onClick={async () => {
                      const response = await axios.get(
                        `${apiUrl}/deals/${record.id}/contributors`,
                        {
                          responseType: "blob",
                        }
                      )
                      FileDownload(
                        response.data,
                        `${record.name}-${record.isIdo ? "ido" : "private"}_contributors.csv`
                      )
                    }}
                  >
                    Download csv
                  </Button>
                )}
                <WithdrawButton deal={record} />
                {!record.started && !record.ended && !record.desiredStartTime && (
                  <DeleteButton recordItemId={record.id} size="small" hideText />
                )}
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  )
}
