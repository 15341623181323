import { GENERATE_LOGO_UPLOAD_URL } from "constants/urls"

import { Group, Text, useMantineTheme } from "@mantine/core"
import type { MantineTheme } from "@mantine/core"
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone"
import type { DropzoneStatus } from "@mantine/dropzone"
import { ImageIcon, UploadIcon, CrossCircledIcon } from "@modulz/radix-icons"
import axios from "axios"
import React from "react"

interface IImageUploadIconProps extends React.SVGAttributes<SVGElement> {
  status: DropzoneStatus
  children?: never
  color?: string
}

async function getPresignedPostUrl(filename: string, mimeType: string) {
  const { data } = await axios.post<{ url: string; filename: string }>(GENERATE_LOGO_UPLOAD_URL, {
    filename,
    mimeType,
  })
  return data
}

async function uploadToS3(fileContents: File) {
  const { url: presignedPostUrl, filename } = await getPresignedPostUrl(
    fileContents.name,
    fileContents.type
  )

  await axios.put(presignedPostUrl, fileContents, {
    headers: { "Content-Type": fileContents.type },
  })

  return filename
}

const ImageUploadIcon: React.FC<IImageUploadIconProps> = ({ status, ...props }) => {
  if (status.accepted) {
    return <UploadIcon {...props} />
  }

  if (status.rejected) {
    return <CrossCircledIcon {...props} />
  }

  return <ImageIcon {...props} />
}

function getIconColor(status: DropzoneStatus, theme: MantineTheme) {
  return status.accepted
    ? theme.colors[theme.primaryColor][6]
    : status.rejected
    ? theme.colors.red[6]
    : theme.colorScheme === "dark"
    ? theme.colors.dark[0]
    : theme.black
}

interface IDropZoneProps {
  onChange?(filename: string): void
}

export const DropZone: React.FC<IDropZoneProps> = ({ onChange }) => {
  const theme = useMantineTheme()
  const [uploadedFilename, setUploadedFilename] = React.useState("")

  const onDrop = async (files: File[]) => {
    for (const file of files) {
      const filename = await uploadToS3(file)
      onChange?.("https://safelaunch-public.s3.eu-west-2.amazonaws.com/" + filename)
      setUploadedFilename(filename)
    }
  }

  return (
    // See results in console after dropping files to Dropzone
    <Dropzone multiple={false} onDrop={onDrop} maxSize={3 * 1024 ** 2} accept={IMAGE_MIME_TYPE}>
      {(status) => (
        <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: "none" }}>
          <ImageUploadIcon
            status={status}
            style={{ width: 80, height: 80, color: getIconColor(status, theme) }}
          />

          <div>
            <Text size="xl" inline>
              {uploadedFilename ? uploadedFilename : "Drag images here or click to select files"}
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              {uploadedFilename
                ? "File uploaded. Click here to replace"
                : "Attach as many files as you like, each file should not exceed 5mb"}
            </Text>
          </div>
        </Group>
      )}
    </Dropzone>
  )
}
