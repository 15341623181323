import dayjs from "dayjs"
import { useQueryClient } from "react-query"
import FileDownload from "js-file-download"
import {
  useApiUrl,
  Drawer,
  Create,
  useCacheQueries,
  useDrawerForm,
  Input,
  List,
  Form,
  Table,
  TextField,
  DateField,
  Typography,
  DatePicker,
  Space,
  Button,
  SaveButton,
  EditButton,
  useEditableTable,
} from "@pankod/refine"

import { IDistribution, IDistributionStage } from "interfaces"
import { useClientsContext } from "clients.context"

const { Title, Text } = Typography

interface IDistributionStagesProps {
  stageIds: string[]
  distributionId: string
  distributionName: string
}

export const DistributionStages: React.FC<IDistributionStagesProps> = ({
  stageIds,
  distributionId,
  distributionName,
}) => {
  const {
    tableProps,
    formProps,
    isEditing,
    saveButtonProps,
    editButtonProps,
    cancelButtonProps,
    setEditId,
  } = useEditableTable<IDistributionStage>({
    resource: "distribution-stages",
    permanentFilter: [
      {
        field: "id",
        operator: "in",
        value: stageIds,
      },
    ],
    queryOptions: { enabled: stageIds.length > 0 },
    syncWithLocation: false,
    redirect: false,
    onMutationSuccess: () => {
      const distributionQueries = getAllQueries("distributions", distributionId)
      for (const query of distributionQueries) {
        queryClient.invalidateQueries(query.queryKey)
      }
      setEditId && setEditId(undefined)
    },
  })
  const getAllQueries = useCacheQueries()
  const queryClient = useQueryClient()
  const { axios } = useClientsContext()
  const apiUrl = useApiUrl()

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
  } = useDrawerForm<IDistributionStage>({
    action: "create",
    resource: "distribution-stages",
    redirect: false,
    onMutationSuccess: () => {
      const distributionQueries = getAllQueries("distributions", distributionId)
      for (const query of distributionQueries) {
        queryClient.invalidateQueries(query.queryKey)
      }
    },
  })

  return (
    <>
      <List resource="distribution-stages" createButtonProps={{ onClick: () => createShow() }}>
        <Form {...formProps}>
          <Table
            {...tableProps}
            rowKey="id"
            key="id"
            onRow={(record) => ({
              onClick: (event: any) => {
                if (event.target.nodeName === "TD") {
                  setEditId && setEditId(record.id)
                }
              },
            })}
          >
            <Table.Column
              dataIndex="name"
              key="name"
              title="Name"
              render={(value, record: IDistributionStage) => {
                if (isEditing(record.id)) {
                  return (
                    <Form.Item name="name" style={{ margin: 0 }}>
                      <Input />
                    </Form.Item>
                  )
                }
                return <TextField value={value} />
              }}
            />

            <Table.Column
              dataIndex="percentOfTotal"
              key="percentOfTotal"
              title="Percent of total"
              render={(value, record: IDistributionStage) => {
                if (isEditing(record.id)) {
                  return (
                    <Form.Item name="percentOfTotal" style={{ margin: 0 }}>
                      <Input />
                    </Form.Item>
                  )
                }
                return <TextField value={value} />
              }}
            />

            <Table.Column
              dataIndex="expectedNumTokensToReceive"
              key="expectedNumTokensToReceive"
              title="Expected # tokens to receive"
              render={(value) => {
                return <TextField value={value} />
              }}
            />

            <Table.Column
              dataIndex="expectedDistributionDate"
              key="expectedDistributionDate"
              title="Expected distribution date"
              render={(value, record: IDistributionStage) => {
                if (isEditing(record.id)) {
                  return (
                    <Form.Item
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        }
                      }}
                      name="expectedDistributionDate"
                      style={{ margin: 0 }}
                    >
                      <DatePicker showTime />
                    </Form.Item>
                  )
                }
                return <DateField format="LLL" value={value} />
              }}
            />

            <Table.Column
              dataIndex="numTokensReceived"
              key="numTokensReceived"
              title="Number of tokens received"
              render={(value, record: IDistributionStage) => {
                const notAsExpected = record.expectedNumTokensToReceive !== record.numTokensReceived
                if (isEditing(record.id)) {
                  return (
                    <Form.Item name="numTokensReceived" style={{ margin: 0 }}>
                      <Input />
                    </Form.Item>
                  )
                }
                return <TextField type={notAsExpected ? "danger" : undefined} value={value} />
              }}
            />

            <Table.Column
              dataIndex="tokensReceivedAt"
              key="tokensReceivedAt"
              title="Tokens received at"
              render={(value, record: IDistributionStage) => {
                if (isEditing(record.id)) {
                  return (
                    <Form.Item
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        }
                      }}
                      name="tokensReceivedAt"
                      style={{ margin: 0 }}
                    >
                      <DatePicker showTime />
                    </Form.Item>
                  )
                }
                return <DateField format="LLL" value={value} />
              }}
            />
            <Table.Column
              dataIndex="numTokensDistributed"
              key="numTokensDistributed"
              title="Number of tokens distributed"
              render={(value, record: IDistributionStage) => {
                if (isEditing(record.id)) {
                  return (
                    <Form.Item name="numTokensDistributed" style={{ margin: 0 }}>
                      <Input />
                    </Form.Item>
                  )
                }
                return <TextField value={value} />
              }}
            />
            <Table.Column
              dataIndex="tokensDistributedAt"
              key="tokensDistributedAt"
              title="Tokens distributed at"
              render={(value, record: IDistributionStage) => {
                if (isEditing(record.id)) {
                  return (
                    <Form.Item
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        }
                      }}
                      name="tokensDistributedAt"
                      style={{ margin: 0 }}
                    >
                      <DatePicker showTime />
                    </Form.Item>
                  )
                }
                return <DateField format="LLL" value={value} />
              }}
            />
            <Table.Column<IDistributionStage>
              title="Actions"
              key="actions"
              render={(_text, item) => {
                if (isEditing(item.id)) {
                  return (
                    <Space>
                      <SaveButton {...saveButtonProps} size="small" />
                      <Button {...cancelButtonProps} size="small">
                        Cancel
                      </Button>
                    </Space>
                  )
                }
                if (
                  item.percentOfTotal &&
                  item.numTokensReceived &&
                  item.expectedNumTokensToReceive === item.numTokensReceived
                ) {
                  return (
                    <Space>
                      <EditButton {...editButtonProps(item.id)} size="small" />
                      <Button
                        style={{ margin: 0 }}
                        onClick={async () => {
                          const response = await axios.get(
                            `${apiUrl}/distribution-stages/${item.id}/csv`,
                            {
                              responseType: "blob",
                            }
                          )
                          FileDownload(response.data, `${distributionName}-${item.name}.csv`)
                        }}
                      >
                        Download csv
                      </Button>
                    </Space>
                  )
                }
                return <EditButton {...editButtonProps(item.id)} size="small" />
              }}
            />
          </Table>
        </Form>
      </List>

      <Drawer {...createDrawerProps}>
        <Create saveButtonProps={createSaveButtonProps}>
          <Form {...createFormProps} layout="vertical" initialValues={{ distributionId }}>
            <Form.Item label="Distribution id" name="distributionId" rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Form>
        </Create>
      </Drawer>
    </>
  )
}
