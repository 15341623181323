import { useState } from "react"
import {
  Edit,
  Form,
  Input,
  Select,
  useSelect,
  IResourceComponentsProps,
  useForm,
} from "@pankod/refine"
import { IDistribution } from "interfaces"

export const DistributionEdit = () => {
  const { formProps, saveButtonProps } = useForm<IDistribution>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps}>
        <Form.Item label="Token price" name="tokenPrice" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Token address" name="tokenAddress" rules={[{ required: false }]}>
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  )
}
