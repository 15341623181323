import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core"
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector"
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from "@web3-react/walletconnect-connector"
import { useCallback } from "react"
import { useQueryClient } from "react-query"

import { ConnectorNames } from "./types"
import { connectorsByName } from "./utils"
import { setupNetwork } from "./wallet"
import { connectorLocalStorageKey } from "./config"

const useAuth = () => {
  const { activate, deactivate, account } = useWeb3React()
  const queryClient = useQueryClient()

  const login = useCallback(
    (connectorID: ConnectorNames) => {
      const connector = connectorsByName[connectorID]
      if (connector) {
        activate(connector, async (error: Error) => {
          if (error instanceof UnsupportedChainIdError) {
            const hasSetup = await setupNetwork()
            if (hasSetup) {
              activate(connector)
            }
          } else {
            window.localStorage.removeItem(connectorLocalStorageKey)
            if (error instanceof NoEthereumProviderError) {
              console.error(`No provider was found`)
            } else if (
              error instanceof UserRejectedRequestErrorInjected ||
              error instanceof UserRejectedRequestErrorWalletConnect
            ) {
              if (connector instanceof WalletConnectConnector) {
                const walletConnector = connector as WalletConnectConnector
                walletConnector.walletConnectProvider = null
              }
              console.error("Please authorize to access your account")
            } else {
              console.error(error.message)
            }
          }
        })

        localStorage.setItem(connectorLocalStorageKey, connectorID)
      } else {
        console.error("The connector config is wrong")
      }
    },
    [activate]
  )

  const logout = useCallback(() => {
    // remove all cached data from our provider/wallet
    queryClient.removeQueries([{ provider: "web3" }])
    deactivate()
    window.localStorage.removeItem(connectorLocalStorageKey)
  }, [deactivate, queryClient])

  return { login, logout }
}

export default useAuth
