import { SUPPORTED_CHAINS } from "constants/chainIds"

import { InjectedConnector } from "@web3-react/injected-connector"
import { ethers } from "ethers"

import { ConnectorNames } from "./types"

const POLLING_INTERVAL = 12000

const injected = new InjectedConnector({ supportedChainIds: SUPPORTED_CHAINS })

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
}

export const getLibrary = (provider: any): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL
  return library
}
