import React from "react"
import { IDeal } from "interfaces"
import { useCreate, Button } from "@pankod/refine"
import add from "date-fns/add"

export const Uncap: React.FC<{ deal: IDeal }> = ({ deal }) => {
  const { mutate, isLoading } = useCreate()

  if (!deal.started || deal.ended || deal.isUncapped) {
    return null
  }

  const hasEnoughTimePassed = deal.desiredStartTime
    ? add(new Date(deal.desiredStartTime), { minutes: 15 }) < new Date()
    : true

  return (
    <Button
      danger
      onClick={() =>
        mutate({
          resource: `deals/${deal.id}/uncap`,
          values: {},
        })
      }
      loading={isLoading}
      disabled={isLoading || !hasEnoughTimePassed}
    >
      {hasEnoughTimePassed ? "Uncap" : "Too early to uncap"}
    </Button>
  )
}
