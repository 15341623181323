import { Create, Form, Input, useForm, InputNumber, DatePicker } from "@pankod/refine"
import dayjs from "dayjs"
import { ISale } from "interfaces"
import { DropZone } from "components/Dropzone.component"

export const SaleCreate = () => {
  const { formProps, saveButtonProps } = useForm<ISale>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps}>
        <Form.Item label="Project Name" name="project" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Item name" name="item" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Item Description" name="description" rules={[{ required: true }]}>
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item label="Project Logo" name="projectLogoUrl" rules={[{ required: true }]}>
          <DropZone />
        </Form.Item>

        <Form.Item label="Item Logo" name="itemLogoUrl" rules={[{ required: true }]}>
          <DropZone />
        </Form.Item>

        <Form.Item label="Price ($)" name="price" rules={[{ required: true }]}>
          <InputNumber min={0} placeholder="0.5" />
        </Form.Item>

        <Form.Item label="Total stock" name="totalStock" rules={[{ required: true }]}>
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          label="Desired start time (in your local time)"
          getValueProps={(value) => {
            return {
              value: value ? dayjs(value) : "",
            }
          }}
          name="desiredStartTime"
          style={{ margin: 0 }}
          rules={[
            { required: false },
            ({ isFieldTouched }) => ({
              validator: async (rule, value) => {
                if (!isFieldTouched("desiredStartTime")) {
                  return
                }
                const isPast = dayjs().isAfter(value)
                if (isPast) {
                  throw new Error("Start time cannot be in the past")
                }
              },
            }),
          ]}
        >
          <DatePicker showTime={{ format: "HH:mm" }} />
        </Form.Item>
      </Form>
    </Create>
  )
}
