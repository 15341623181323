import React from "react"
import { ISale } from "interfaces"
import { useCreate, Button } from "@pankod/refine"

export const EndSale: React.FC<{ sale: ISale }> = ({ sale }) => {
  const { mutate, isLoading } = useCreate()

  if (!sale.startedAt || sale.endedAt) {
    return null
  }
  const percentSold = (sale.numSold * 100) / sale.totalStock

  return (
    <Button
      danger
      onClick={() =>
        mutate({
          resource: `sales/${sale.id}/end`,
          values: {},
        })
      }
      loading={isLoading}
      disabled={isLoading}
    >
      {percentSold.toFixed(0)}% sold - End
    </Button>
  )
}
