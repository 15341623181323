import { useState } from "react"
import { formatNumber } from "utils/number"
import { Text } from "@mantine/core"
import dayjs from "dayjs"
import { Checkbox, DatePicker, Edit, Form, Spin, Input, useForm, Select } from "@pankod/refine"
import { IDeal } from "interfaces"
import { DropZone } from "components/Dropzone.component"
import { CHAIN_OPTIONS } from "./chain"

export const DealEdit = () => {
  const { formProps, saveButtonProps, queryResult, form } = useForm<IDeal>({ action: "edit" })
  const [dealSizeHuman, setDealSizeHuman] = useState(null)
  const [minAllocHuman, setMinAllocHuman] = useState(null)

  const onDealSize = (e: any) => {
    form.setFieldsValue({ dealSize: e.target.value })
    setDealSizeHuman(e.target.value.toBigNumber(0).toFixed(0))
  }
  const onMinAlloc = (e: any) => {
    form.setFieldsValue({ minAllocation: e.target.value })
    setMinAllocHuman(e.target.value.toBigNumber(0).toFixed(0))
  }

  if (!queryResult) {
    return <Spin />
  }

  const { data, isLoading } = queryResult
  if (isLoading) {
    return <Spin />
  }
  if (!data) {
    return <Text>An error occurred getting data for the deal</Text>
  }
  const deal = data.data

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps}>
        <Form.Item label="Project Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="IDO?"
          name="isIdo"
          rules={[{ required: true }]}
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox />
        </Form.Item>

        <Form.Item label="About" name="about" rules={[{ required: true }]}>
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item label="Price" name="weightedAveragePrice" rules={[{ required: true }]}>
          <Input placeholder="$0.42" />
        </Form.Item>

        <Form.Item label="Vesting schedule" name="vestingSchedule" rules={[{ required: true }]}>
          <Input placeholder="10% TGE then 8% every 3 weeks" />
        </Form.Item>

        <Form.Item label="Discord invite URL" name="discordInviteUrl" rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Logo url" name="logoUrl" rules={[{ required: true }]}>
          <DropZone />
        </Form.Item>

        <Form.Item
          label="Chain"
          name="chain"
          rules={[{ required: true }]}
          style={{ display: "inline-block", width: "500px" }}
        >
          <Select options={CHAIN_OPTIONS} />
        </Form.Item>
        <Text>Contact Naz on Discord if the desired chain is not listed.</Text>

        <Form.Item
          label="Project telegram url"
          name="projectTelegramUrl"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project twitter url"
          name="projectTwitterUrl"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project website url"
          name="projectWebsiteUrl"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project whitepaper url"
          name="projectWhitepaperUrl"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project discord url"
          name="projectDiscordUrl"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        {dealSizeHuman && (
          <Text>Deal size will be ${formatNumber(dealSizeHuman)} in human terms</Text>
        )}
        <Form.Item label="Deal size" name="dealSize" rules={[{ required: true }]}>
          <Input onChange={onDealSize} />
        </Form.Item>

        {minAllocHuman && (
          <Text>Minimum allocation will be ${formatNumber(minAllocHuman)} in human terms</Text>
        )}
        <Form.Item
          label="Minimum allocation size"
          name="minAllocation"
          rules={[{ required: true }]}
        >
          <Input onChange={onMinAlloc} />
        </Form.Item>

        {!deal.started && (
          <Form.Item
            label="Desired start time (in your local time)"
            getValueProps={(value) => {
              return {
                value: value ? dayjs(value) : "",
              }
            }}
            name="desiredStartTime"
            style={{ margin: 0 }}
            rules={[
              { required: false },
              ({ isFieldTouched }) => ({
                validator: async (rule, value) => {
                  if (!isFieldTouched("desiredStartTime")) {
                    return
                  }
                  const isPast = dayjs().isAfter(value)
                  if (isPast) {
                    throw new Error("Start time cannot be in the past")
                  }
                },
              }),
            ]}
          >
            <DatePicker showTime={{ format: "HH:mm" }} />
          </Form.Item>
        )}

        <Text mt="md">
          If you are having issues Editing the deal please{" "}
          <a
            href="https://www.loom.com/share/1554f1d748da49ed99dc7cb0a6dcebff"
            target="_blank"
            rel="noreferrer"
          >
            see this video
          </a>{" "}
          on how to fix.
        </Text>
      </Form>
    </Edit>
  )
}
