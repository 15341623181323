export const BSC_MAINNET_ID = 56
export const ETH_MAINNET_ID = 1
export const POLYGON_MAINNET_ID = 137

export type SupportedChain = typeof BSC_MAINNET_ID
// | typeof ETH_MAINNET_ID
// | typeof POLYGON_MAINNET_ID

export const SUPPORTED_CHAINS = [BSC_MAINNET_ID]

export const isSupportedChain = (chainId?: number): chainId is SupportedChain => {
  if (chainId === undefined) {
    return false
  }
  return SUPPORTED_CHAINS.includes(chainId)
}
