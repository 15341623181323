export const CHAIN_OPTIONS = [
  { label: "Algorand", value: "ALGORAND" },
  { label: "Solana", value: "SOLANA" },
  { label: "Ethereum", value: "ETH" },
  { label: "Binance Smart Chain", value: "BSC" },
  { label: "Avalanche", value: "AVAX" },
  { label: "Celo", value: "CELO" },
  { label: "Polygon", value: "POLYGON" },
  { label: "Fantom", value: "FTM" },
]
