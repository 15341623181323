import React from "react"
import axios, { AxiosInstance } from "axios"

export interface IClientsContext {
  axios: AxiosInstance
}

const defaultProvider: IClientsContext = {
  axios: axios.create(),
}

const ClientsContext = React.createContext<IClientsContext>(defaultProvider)

export const useClientsContext = () => {
  const context = React.useContext(ClientsContext)

  if (!context) {
    throw new Error("Component rendered outside the provider tree")
  }

  return context
}

const parseErrorDetail = (detail: any) => {
  if (Array.isArray(detail)) {
    return JSON.stringify(detail)
  }
  return detail
}

export const ClientsProvider: React.FC = ({ children }) => {
  const axiosInstance = axios.create()
  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const detail = parseErrorDetail(error.response?.data?.detail)
      const message = error.response?.data?.message

      const customError = {
        ...error,
        message: detail || message,
        statusCode: error.response?.status,
      }

      return Promise.reject(customError)
    }
  )
  return (
    <ClientsContext.Provider value={{ axios: axiosInstance }}>{children}</ClientsContext.Provider>
  )
}
