import { usePeddlerAllocation } from "./dealControllerQueries"
import React from "react"
import { IDeal } from "interfaces"
import { Spin, TextField } from "@pankod/refine"

export const PeddlerAllocation: React.FC<{ deal: IDeal }> = ({ deal }) => {
  const { data: allocation, isLoading } = usePeddlerAllocation(deal)

  if (isLoading) {
    return <Spin />
  }
  if (!allocation) {
    return null
  }

  return <TextField value={allocation} />
}
