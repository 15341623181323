import { useBaseCoefficient } from "./dealControllerQueries"

// we read the base coefficient from the deal controller that is being shared by
// all deals to figure out what the current base allocations are
export const BaseAllocation = () => {
  const { data: baseCoefficient } = useBaseCoefficient()

  if (!baseCoefficient) {
    return null
  }

  return (
    <div>
      <p>All deals currently have {baseCoefficient}x the normal allocation.</p>
    </div>
  )
}
