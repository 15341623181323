import "./bootstrap.ts"
import { Refine, AuthProvider } from "@pankod/refine"
import routerProvider from "@pankod/refine-react-router"
import { API_URL, LOGIN_URL } from "constants/urls"
import { ConnectWalletButton } from "features/web3/components/ConnectWalletButton.component"

import "@pankod/refine/dist/styles.min.css"
import simpleRestDataProvider from "@pankod/refine-simple-rest"

import {
  DistributionList,
  DistributionShow,
  DistributionCreate,
  DistributionEdit,
} from "pages/distributions"

import { SaleList, SaleCreate, SaleEdit } from "pages/sales"

import { PotentialDealCreate, PotentialDealList } from "pages/potentialdeals"
import { DealList, DealCreate, DealEdit } from "pages/deals"
import { useClientsContext } from "clients.context"
import { PotentialDealEdit } from "pages/potentialdeals"
import { PotentialDealShow } from "pages/potentialdeals/show"

function App() {
  const { axios: axiosInstance } = useClientsContext()
  const dataProvider = simpleRestDataProvider(API_URL, axiosInstance as any)

  const authProvider: AuthProvider = {
    login: async ({ username, password, remember }) => {
      const formData = new FormData()
      formData.append("username", username)
      formData.append("password", password)

      const data = await axiosInstance({
        url: LOGIN_URL,
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      const { accessToken, user } = data.data
      if (!accessToken) {
        return Promise.reject()
      }
      axiosInstance.defaults.headers.common.Authorization = "Bearer " + accessToken
      localStorage.setItem("token", accessToken)
      localStorage.setItem("user", JSON.stringify(user))
    },
    logout: async () => {
      localStorage.removeItem("token")
      axiosInstance.defaults.headers.common = {}
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      const token = localStorage.getItem("token")
      if (token) {
        axiosInstance.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        }
        return Promise.resolve()
      }
      return Promise.reject()
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
      const user = localStorage.getItem("user")
      if (user) {
        return Promise.resolve(JSON.parse(user))
      }
    },
  }

  return (
    <Refine
      dataProvider={dataProvider}
      routerProvider={routerProvider}
      authProvider={authProvider}
      Header={ConnectWalletButton}
      resources={[
        {
          name: "sales",
          list: SaleList,
          create: SaleCreate,
          edit: SaleEdit,
        },
        {
          name: "potentialdeals",
          list: PotentialDealList,
          create: PotentialDealCreate,
          edit: PotentialDealEdit,
          show: PotentialDealShow,
        },
        {
          name: "deals",
          list: DealList,
          create: DealCreate,
          edit: DealEdit,
        },
        {
          name: "distributions",
          list: DistributionList,
          show: DistributionShow,
          create: DistributionCreate,
          edit: DistributionEdit,
        },
        {
          name: "distribution-stages",
        },
      ]}
    ></Refine>
  )
}

export default App

/* LoginPage={Login} */
