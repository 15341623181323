import { getAddress } from "@ethersproject/address"
import { ethers } from "ethers"
import { BigNumber } from "@ethersproject/bignumber"
import { useQuery } from "react-query"
import invariant from "tiny-invariant"
import ERC20_ABI from "abis/erc20.json"
import { AddressZero } from "@ethersproject/constants"
import { Contract } from "@ethersproject/contracts"
import { Web3Provider, JsonRpcSigner } from "@ethersproject/providers"
import { useWeb3React } from "@web3-react/core"
import { useMemo } from "react"

export const BSC_RPC_NODE_URLS = [
  "https://bsc-dataseed1.binance.org/",
  "https://bsc-dataseed2.binance.org/",
  "https://bsc-dataseed3.binance.org/",
  "https://bsc-dataseed4.binance.org/",
]
// randomly pick an element from BSC_RPC_NODE_URLS
const getRandomRpcUrl = () => {
  const index = Math.floor(Math.random() * BSC_RPC_NODE_URLS.length)
  return BSC_RPC_NODE_URLS[index]
}
// used when the user does not have a wallet connected/installed on their browser
const getSimpleRpcProvider = () => new ethers.providers.JsonRpcProvider(getRandomRpcUrl())

// Return a contract connected to a random RPC node
// When used inside of a useQuery instance and combined with auto back-off retry
// this gives a good chance of returning a result in a timely manner
export const getFastContract = (address: string, abi: any) => {
  const provider = getSimpleRpcProvider()
  return new ethers.Contract(address, abi, provider)
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}
// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function useContract(address: string | undefined, ABI: any): Contract | null {
  const { library, account } = useWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library || !isAddress(address)) return null
    try {
      return getContract(address, ABI, library, account ? account : undefined)
    } catch (error) {
      console.error("Failed to get contract", error)
      throw error
    }
  }, [address, ABI, library, account])
}

export function useTokenContract(tokenAddress: string): Contract | null {
  return useContract(tokenAddress, ERC20_ABI)
}

// const BUSD_ADDRESS = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" // <- busd 
const BUSD_ADDRESS = "0x55d398326f99059fF775485246999027B3197955" // <- usdt


export const useBusdBalance = (accountAddress: string) => {
  const contract = useTokenContract(BUSD_ADDRESS)

  return useQuery<BigNumber>(
    [{ provider: "web3", contractAddress: BUSD_ADDRESS, accountAddress, variable: "balance" }],
    async () => {
      invariant(contract, "Contract not given")
      invariant(isAddress(accountAddress), "Account address is invalid")

      return contract.balanceOf(accountAddress)
    },
    {
      enabled: Boolean(contract) && Boolean(isAddress(accountAddress)),
    }
  )
}
