import invariant from "tiny-invariant"
import { useAllocationContract } from "./useAllocationContract.hook"
import { useQuery } from "react-query"

export const useIsUncapped = (contractAddress: string) => {
  const contract = useAllocationContract(contractAddress)

  return useQuery<boolean>(
    [
      {
        provider: "web3",
        variable: "isUncapped",
        contract: contractAddress,
      },
    ],
    async () => {
      invariant(contract, "contract is undefined")

      return contract.isUncapped()
    },
    {
      enabled: Boolean(contract),
    }
  )
}
