import { Alert } from "antd"
import {
  List,
  Form,
  Show,
  TextField,
  DateField,
  useShow,
  Typography,
  Tag,
  useOne,
  Space,
  Button,
  SaveButton,
  EditButton,
  IResourceComponentsProps,
  MarkdownField,
  useMany,
  useTable,
} from "@pankod/refine"

import { IDistribution, IDistributionNotification } from "interfaces"
import { DistributionStages } from "./stages"

const { Title, Text } = Typography
// map numbers to antd alert types
const severityToAlertType = (severity: number): "info" | "warning" | "error" => {
  switch (severity) {
    case 1:
      return "info"
    case 2:
      return "warning"
    case 3:
      return "error"
    default:
      return "info"
  }
}

// component which renders a list of alerts
const Alerts = ({ alerts }: { alerts?: IDistributionNotification[] }) => {
  if (!alerts) {
    return null
  }

  return (
    <>
      {alerts
        .sort((a, b) => b.severity - a.severity)
        .map((alert, index) => (
          <div key={index}>
            <Alert message={alert.message} type={severityToAlertType(alert.severity)} showIcon />
            <Space />
          </div>
        ))}
    </>
  )
}

export const DistributionShow = () => {
  const { queryResult } = useShow<IDistribution>()
  const { data, isLoading } = queryResult
  const record = data?.data

  return (
    <Show isLoading={isLoading} canEdit>
      <Alerts alerts={record?.notifications} />
      <Title level={5}>Name</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>BUSD amount (minus fee)</Title>
      <Text>{record?.raiseInBusdAfterFee}</Text>

      <Title level={5}>Token price</Title>
      <Text>{record?.tokenPrice}</Text>

      <Title level={5}>Expected total tokens</Title>
      <Text>{record?.expectedTotalTokens}</Text>

      <Title level={5}>Token address</Title>
      <Text>{record?.tokenAddress || "none set"}</Text>
      <br />

      {record && (
        <DistributionStages
          stageIds={record.stages}
          distributionId={record.id}
          distributionName={record.name}
        />
      )}
    </Show>
  )
}
