import React from "react"
import {
  List,
  Table,
  TextField,
  useTable,
  IResourceComponentsProps,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useMany,
  useSelect,
  BooleanField,
  TagField,
  FilterDropdown,
  Select,
  ShowButton,
} from "@pankod/refine"
import { IDistribution } from "interfaces"

export const DistributionList = () => {
  const { tableProps, sorter } = useTable<IDistribution>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
  })

  return (
    <List canCreate>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IDistribution>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => (
            <ShowButton size="small" recordItemId={record.id} hideText />
          )}
        />
      </Table>
    </List>
  )
}
