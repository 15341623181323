import {
  Divider,
  Image,
  useShow,
  DatePicker,
  BooleanField,
  Checkbox,
  Show,
  useCreate,
  Form,
  Input,
  Button,
  useNavigation,
} from "@pankod/refine"
import { Text, Title } from "@mantine/core"
import { IPotentialDeal, IDeal } from "interfaces"
import dayjs from "dayjs"
import { capitalise } from "utils/format"

// require logourl
const LINKS: Array<keyof IPotentialDeal> = [
  "telegramUrl",
  "twitterUrl",
  "websiteUrl",
  "whitepaperUrl",
  "discordUrl",
  "pitchDeckUrl",
]

interface IConvertToDealProps {
  record: IPotentialDeal
}

const ConvertToDeal: React.FC<IConvertToDealProps> = ({ record }) => {
  const { edit } = useNavigation()

  // backend will return a deal
  const { mutate, isLoading, data } = useCreate<IDeal>()

  const handleSubmit = (values: any) => {
    mutate(
      {
        resource: `potentialdeals/${record.id}/convert-to-deal`,
        values,
      },
      {
        onSuccess: (deal) => {
          edit("deals", deal.data.id)
        },
      }
    )
    console.log({ data })
  }

  if (!record.logoUrl) {
    return <Text>You must first upload a logo</Text>
  }
  if (!record.vestingSchedule) {
    return <Text>Vesting schedule must be specified</Text>
  }
  if (!record.chain) {
    return <Text>Chain must be specified</Text>
  }
  return (
    <Form onFinish={handleSubmit}>
      <div style={{ width: 500 }}>
        <Form.Item label="SAFT size ($)" name="saftSizeUsd" rules={[{ required: true }]}>
          <Input placeholder="100000" />
        </Form.Item>

        <Form.Item label="Fee (%)" name="feePercentage" rules={[{ required: true }]}>
          <Input placeholder="10" />
        </Form.Item>

        <Form.Item label="Discord invite url" name="discordInviteUrl" rules={[{ required: true }]}>
          <Input placeholder="https://discord.com/ASJ9g2bv" />
        </Form.Item>

        <Form.Item label="Min allocation ($)" name="minAllocationUsd" rules={[{ required: true }]}>
          <Input placeholder="30" />
        </Form.Item>

        <Form.Item
          label="Desired start time (in your local time)"
          getValueProps={(value) => {
            return {
              value: value ? dayjs(value) : "",
            }
          }}
          name="desiredStartTime"
          style={{ margin: 0 }}
          rules={[
            { required: false },
            ({ isFieldTouched }) => ({
              validator: async (rule, value) => {
                if (!isFieldTouched("desiredStartTime")) {
                  return
                }
                const isPast = dayjs().isAfter(value)
                if (isPast) {
                  throw new Error("Start time cannot be in the past")
                }
              },
            }),
          ]}
        >
          <DatePicker showTime={{ format: "HH:mm" }} />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
          Create deal
        </Button>
      </div>
    </Form>
  )
}

export const PotentialDealShow = () => {
  const { queryResult } = useShow<IPotentialDeal>()
  const { data, isLoading } = queryResult
  const record = data?.data

  return (
    <Show isLoading={isLoading}>
      <Image src={record?.logoUrl} width={120} />

      <div>
        <div style={{ display: "inline-block", width: "700px" }}>
          <Title order={5}>Name</Title>
          <Text>{record?.name}</Text>

          <Title order={5}>Chain</Title>
          {record?.chain ? <Text>{record.chain}</Text> : "Not specified"}

          <Title order={5}>About</Title>
          <Text>{record?.about}</Text>

          <Title order={5}>IDO?</Title>
          <BooleanField value={record?.isIdo} />

          <Title order={5}>Price</Title>
          <Text>{record?.weightedAveragePrice}</Text>

          <Title order={5}>Vesting schedule</Title>
          <Text>{record?.vestingSchedule}</Text>
        </div>

        <div style={{ display: "inline-block", width: "500px" }}>
          {LINKS.map((linkKey) => {
            if (!record) {
              return null
            }
            const link = record[linkKey]
            return (
              <>
                <Title order={5}>{capitalise(linkKey.slice(0, -3))}</Title>
                {link ? <a href={link as string}>{link}</a> : "Not specified"}
              </>
            )
          })}
        </div>
      </div>
      {record && (
        <>
          <Divider plain dashed>
            Convert to deal
          </Divider>
          <ConvertToDeal record={record} />
        </>
      )}
    </Show>
  )
}
