import React from "react"
import { IDeal } from "interfaces"
import { useCreate, Button } from "@pankod/refine"

export const EndDeal: React.FC<{ deal: IDeal }> = ({ deal }) => {
  const { mutate, isLoading } = useCreate()

  if (!deal.started || deal.ended) {
    return null
  }

  const raisePercent = deal.totalRaised.toBigNumber(2).div(deal.dealSize.toBigNumber(0)).toNumber()

  const canEnd = raisePercent >= 99

  return (
    <Button
      danger
      onClick={() =>
        mutate({
          resource: `deals/${deal.id}/end`,
          values: {},
        })
      }
      loading={isLoading}
      disabled={isLoading || !canEnd}
    >
      {canEnd ? "End" : `${raisePercent}% raised`}
    </Button>
  )
}
