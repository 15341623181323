import { useEffect } from "react"

import { connectorLocalStorageKey } from "./config"
import { ConnectorNames } from "./types"
import useAuth from "./useAuth.hook"

const useEagerConnect = () => {
  const { login } = useAuth()

  useEffect(() => {
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames

    if (connectorId) {
      login(connectorId)
    }
  }, [login])
}

export default useEagerConnect
