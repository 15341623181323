import React from "react"
import { useWeb3React } from "@web3-react/core"
import useAuth from "../useAuth.hook"
import { Button } from "@pankod/refine"
import useEagerConnect from "../useEagerConnect.hook"
import { ConnectorNames } from "../types"
import { snipAddress } from "utils/format"

export const ConnectWalletButton: React.FC = () => {
  useEagerConnect()
  const { login, logout } = useAuth()
  const { account } = useWeb3React()

  if (account) {
    const walletAddress = snipAddress(account)
    return (
      <Button color="dark" onClick={logout}>
        {walletAddress}
      </Button>
    )
  }
  return <Button onClick={() => login(ConnectorNames.Injected)}>Connect Wallet</Button>
}
