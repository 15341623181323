import { useState } from "react"
import { useBooleanToggle } from "@mantine/hooks"
import dayjs from "dayjs"
import { Title, Text, Space, Group } from "@mantine/core"
import {
  Switch,
  DatePicker,
  Create,
  Form,
  Input,
  InputNumber,
  Select,
  useSelect,
  IResourceComponentsProps,
  useForm,
} from "@pankod/refine"
import { IDistribution } from "interfaces"

const PERIOD_OPTIONS = [
  {
    label: "days",
    value: "DAY",
  },
  {
    label: "weeks",
    value: "WEEK",
  },
  {
    label: "months",
    value: "MONTH",
  },
  {
    label: "quarters",
    value: "QUARTER",
  },
  {
    label: "half-years",
    value: "HALF_YEAR",
  },
  {
    label: "years",
    value: "YEAR",
  },
]

export const DistributionCreate = () => {
  const { formProps, saveButtonProps } = useForm<IDistribution>()
  const [opened, toggle] = useBooleanToggle(false)

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps}>
        <Form.Item label="Deal id" name={["distributionIn", "dealId"]} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Raise in BUSD minus fee"
          name={["distributionIn", "raiseInBusdAfterFee"]}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Token price"
          name={["distributionIn", "tokenPrice"]}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Token address"
          name={["distributionId", "tokenAddress"]}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Title order={3}>Vesting schedule</Title>
        <Text mt="sm">
          Select between two different vesting types. The difference depends on the schedule given
          by the project.
        </Text>
        <Text mt="sm">
          Vesting type A is a format like: "10% released at TGE, 3 month cliff, then 5% released
          every week"
        </Text>
        <Text mt="sm">
          Vesting type B is a format like: "10% released at TGE, 3 month cliff, then every month for
          a year"
        </Text>

        <Space h="md" />

        <Text component="span" mr="md">
          Vesting Type A
        </Text>

        <Switch checked={opened} onChange={() => toggle()} />
        <Text component="span" ml="md">
          Vesting Type B
        </Text>

        <Space h="md" />

        <Form.Item
          label="TGE Date"
          getValueProps={(value) => {
            return {
              value: value ? dayjs(value) : "",
            }
          }}
          name={["vestingSchedule", "tgeVest", "tgeDate"]}
          rules={[{ required: true }]}
        >
          <DatePicker showTime={{ format: "HH:mm" }} />
        </Form.Item>

        <Form.Item label="TGE" style={{ margin: 0 }}>
          <Form.Item
            name={["vestingSchedule", "tgeVest", "percentUnlockedTge"]}
            rules={[{ required: true }]}
            style={{ display: "inline-block", width: "100px" }}
          >
            <InputNumber min={0} max={100} />
          </Form.Item>

          <Text component="span">% released at TGE, followed by a</Text>

          <Form.Item
            name={["vestingSchedule", "tgeVest", "cliff", "numPeriods"]}
            rules={[{ required: true }]}
            style={{ display: "inline-block", marginLeft: "8px" }}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            name={["vestingSchedule", "tgeVest", "cliff", "periodType"]}
            rules={[{ required: true }]}
            style={{ display: "inline-block", width: "200px" }}
          >
            <Select options={PERIOD_OPTIONS} />
          </Form.Item>

          <Text component="span" ml="sm">
            cliff
          </Text>
        </Form.Item>

        <Space h="md" />

        {opened && (
          <Form.Item label="Stage vest B (by duration)" colon={false} style={{ margin: 0 }}>
            <Text component="span">Tokens released every</Text>

            <Form.Item
              name={["vestingSchedule", "stageVests", "payoutInterval", "numPeriods"]}
              rules={[{ required: true }]}
              style={{ display: "inline-block", marginLeft: "8px" }}
            >
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              name={["vestingSchedule", "stageVests", "payoutInterval", "periodType"]}
              rules={[{ required: true }]}
              style={{ display: "inline-block", width: "200px" }}
            >
              <Select options={PERIOD_OPTIONS} />
            </Form.Item>

            <Text component="span" ml="sm">
              for
            </Text>

            <Form.Item
              name={["vestingSchedule", "stageVests", "totalDuration", "numPeriods"]}
              rules={[{ required: true }]}
              style={{ display: "inline-block", marginLeft: "8px" }}
            >
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              name={["vestingSchedule", "stageVests", "totalDuration", "periodType"]}
              rules={[{ required: true }]}
              style={{ display: "inline-block", width: "200px" }}
            >
              <Select options={PERIOD_OPTIONS} />
            </Form.Item>
          </Form.Item>
        )}

        {!opened && (
          <Form.Item label="Stage vest A (by percent)" colon={false} style={{ margin: 0 }}>
            <Form.Item
              name={["vestingSchedule", "stageVests", "percentEachStage"]}
              rules={[{ required: true }]}
              style={{ display: "inline-block", width: "100px" }}
            >
              <InputNumber min={0} max={100} />
            </Form.Item>

            <Text component="span">% every</Text>

            <Form.Item
              name={["vestingSchedule", "stageVests", "interval", "numPeriods"]}
              rules={[{ required: true }]}
              style={{ display: "inline-block", marginLeft: "8px" }}
            >
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              name={["vestingSchedule", "stageVests", "interval", "periodType"]}
              rules={[{ required: true }]}
              style={{ display: "inline-block", width: "200px" }}
            >
              <Select options={PERIOD_OPTIONS} />
            </Form.Item>
          </Form.Item>
        )}
      </Form>
    </Create>
  )
}
