import { Checkbox, Create, Form, Input, useForm, Select } from "@pankod/refine"
import { Text } from "@mantine/core"
import { IPotentialDeal } from "interfaces"
import { DropZone } from "components/Dropzone.component"
import { CHAIN_OPTIONS } from "../deals/chain"

export const PotentialDealCreate = () => {
  const { formProps, saveButtonProps } = useForm<IPotentialDeal>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps}>
        <Form.Item label="Project Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="About" name="about" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="IDO?"
          name="isIdo"
          rules={[{ required: false }]}
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox />
        </Form.Item>

        <Form.Item label="Price" name="weightedAveragePrice" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Vesting schedule" name="vestingSchedule" rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Logo url" name="logoUrl" rules={[{ required: false }]}>
          <DropZone />
        </Form.Item>

        <Form.Item
          label="Chain"
          name="chain"
          rules={[{ required: true }]}
          style={{ display: "inline-block", width: "500px" }}
        >
          <Select options={CHAIN_OPTIONS} />
        </Form.Item>
        <Text>Contact Naz on Discord if the desired chain is not listed.</Text>

        <Form.Item label="Telegram URL" name="telegramUrl" rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Twitter URL" name="twitterUrl" rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Website URL" name="websiteUrl" rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Whitepaper URL" name="whitepaperUrl" rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Discord URL" name="discordUrl" rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Pitch deck URL" name="pitchDeckUrl" rules={[{ required: false }]}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
