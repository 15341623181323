import React from "react"
import { ISale } from "interfaces"
import { useWithdraw } from "./contractMutations"
import { useBusdRemaining } from "./contractQueries"
import { formatNumber } from "utils/number"
import { Button, TextField, Spin } from "@pankod/refine"

export const WithdrawButton: React.FC<{ sale: ISale }> = ({ sale }) => {
  const { mutate: withdraw, isLoading } = useWithdraw(sale.contractAddress)
  const { data: busdRemaining, isLoading: isBusdLoading } = useBusdRemaining(sale)
  if (isBusdLoading) {
    return <Spin />
  }
  if (!sale.endedAt || !busdRemaining) {
    return null
  }
  if (busdRemaining.isZero()) {
    return <TextField value="BUSD withdrawn" />
  }

  return (
    <Button disabled={isLoading} loading={isLoading} onClick={() => withdraw(sale.saleId)}>
      Withdraw {formatNumber(busdRemaining.toFixed(0))} BUSD
    </Button>
  )
}
