import React from "react"
import { formatNumber } from "utils/number"
import {
  Button,
  useUpdate,
  EditButton,
  DateField,
  Space,
  ShowButton,
  List,
  Table,
  TextField,
  useTable,
  BooleanField,
} from "@pankod/refine"
import { IPotentialDeal } from "interfaces"

export const PotentialDealList = () => {
  const { tableProps } = useTable<IPotentialDeal>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
  })
  const { mutate } = useUpdate<IPotentialDeal>()

  return (
    <List canCreate>
      <Table {...tableProps} rowKey="ended_at">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="isIdo"
          key="isIdo"
          title="IDO?"
          render={(value) => <BooleanField value={value} falseIcon={<></>} />}
        />

        <Table.Column
          dataIndex="endedAt"
          key="endedAt"
          title="Ended?"
          render={(value) => <BooleanField value={Boolean(value)} falseIcon={<></>} />}
        />

        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="Created At"
          render={(value) => <DateField format="LLL" value={value} />}
        />

        <Table.Column
          dataIndex="numUpvotes"
          key="numUpvotes"
          title="# Upvotes"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="numDownvotes"
          key="numDownvotes"
          title="# Downvotes"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="totalUpvoteContributed"
          key="totalUpvoteContributed"
          title="Total upvote contributed ($)"
          render={(value) => <TextField value={formatNumber(value)} />}
        />

        <Table.Column
          dataIndex="totalDownvoteContributed"
          key="totalDownvoteContributed"
          title="Total downvote contributed ($)"
          render={(value) => <TextField value={formatNumber(value)} />}
        />

        {/*<Table.Column
          dataIndex="totalAverageUpvoteContributed"
          key="totalAverageUpvoteContributed"
          title="Total average upvote contributed ($)"
          render={(value) => <TextField value={formatNumber(value)} />}
        />

        <Table.Column
          dataIndex="totalAverageDownvoteContributed"
          key="totalAverageDownvoteContributed"
          title="Total average downvote contributed ($)"
          render={(value) => <TextField value={formatNumber(value)} />}
        />
        */}

        <Table.Column<IPotentialDeal>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />

              <EditButton size="small" recordItemId={record.id} hideText />

              <Button
                onClick={() => {
                  mutate({
                    id: record.id,
                    resource: "potentialdeals",
                    values: { ended: !Boolean(record.endedAt) },
                  })
                }}
              >
                {record.endedAt ? "Reopen voting" : "End voting"}
              </Button>
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
