import { useState } from "react"
import { Edit, Form, Input, useForm, DatePicker, InputNumber } from "@pankod/refine"
import { ISale } from "interfaces"
import { Text } from "@mantine/core"
import dayjs from "dayjs"
import { DropZone } from "components/Dropzone.component"

export const SaleEdit = () => {
  const { formProps, saveButtonProps, form } = useForm<ISale>()
  const [priceHuman, setPriceHuman] = useState(null)

  const onPrice = (e: any) => {
    form.setFieldsValue({ price: e.target.value })
    setPriceHuman(e.target.value.toBigNumber(0).toFixed(0))
  }

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps}>
        <Form.Item label="Project Name" name="project" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Item name" name="item" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Item Description" name="description" rules={[{ required: true }]}>
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item label="Project Logo" name="projectLogoUrl" rules={[{ required: true }]}>
          <DropZone />
        </Form.Item>

        <Form.Item label="Item Logo" name="itemLogoUrl" rules={[{ required: true }]}>
          <DropZone />
        </Form.Item>

        {priceHuman && <Text>Price will be ${priceHuman} in human terms</Text>}
        <Form.Item label="Price" name="price" rules={[{ required: true }]}>
          <Input onChange={onPrice} />
        </Form.Item>

        <Form.Item label="Total stock" name="totalStock" rules={[{ required: true }]}>
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          label="Desired start time (in your local time)"
          getValueProps={(value) => {
            return {
              value: value ? dayjs(value) : "",
            }
          }}
          name="desiredStartTime"
          style={{ margin: 0 }}
          rules={[
            { required: false },
            ({ isFieldTouched }) => ({
              validator: async (rule, value) => {
                if (!isFieldTouched("desiredStartTime")) {
                  return
                }
                const isPast = dayjs().isAfter(value)
                if (isPast) {
                  throw new Error("Start time cannot be in the past")
                }
              },
            }),
          ]}
        >
          <DatePicker showTime={{ format: "HH:mm" }} />
        </Form.Item>
      </Form>
    </Edit>
  )
}
