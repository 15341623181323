import invariant from "tiny-invariant"
import { BigNumber } from "ethers"
import { useAllocationContract } from "./useAllocationContract.hook"
import { useMutation } from "react-query"

export const useWithdraw = (contractAddress: string) => {
  const contract = useAllocationContract(contractAddress)

  return useMutation(
    async (remaining: BigNumber) => {
      invariant(contract, "Contract not given")

      const tx = await contract.withdrawToken(remaining)
      const receipt = await tx.wait()
      if (receipt.status !== 1) {
        throw new Error("Transaction failed")
      }
      return
    },
    {
      onError: (err: any) => {
        const message = err?.data?.message || err?.message || err
        console.error(message)
      },
    }
  )
}
