import React from "react"
import { useClientsContext } from "clients.context"
import FileDownload from "js-file-download"
import { EndSale } from "./EndSale.component"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import { formatNumber } from "utils/number"
import {
  useApiUrl,
  DateField,
  EditButton,
  List,
  Table,
  TextField,
  useTable,
  Button,
  Space,
} from "@pankod/refine"
import { ISale } from "interfaces"
import { WithdrawButton } from "./WithdrawButton.component"

export const SaleList = () => {
  const { tableProps, sorter } = useTable<ISale>()
  const { axios } = useClientsContext()
  const apiUrl = useApiUrl()

  return (
    <List canCreate>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="project"
          key="project"
          title="Project"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="item"
          key="item"
          title="Item name"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="price"
          key="price"
          title="Price ($)"
          render={(value) => <TextField value={formatNumber(value.toBigNumber(0).toFixed(0))} />}
        />

        <Table.Column
          dataIndex="numSold"
          key="numSold"
          title="# Sold"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="totalStock"
          key="totalStock"
          title="Total stock"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="desiredStartTime"
          key="desiredStartTime"
          title="Start Time"
          render={(value, record: ISale) => {
            if (record.endedAt) {
              return <TextField value="Ended" />
            }
            if (record.startedAt) {
              return <TextField value="Started" />
            }
            if (!record.desiredStartTime) {
              return <TextField value="No start time set" />
            }

            return (
              <>
                <DateField format="LLL" value={value} />
                <br />
                (in <TextField value={formatDistanceToNowStrict(new Date(value))} />)
              </>
            )
          }}
        />

        <Table.Column
          dataIndex="endedAt"
          key="endedAt"
          title="Ended At"
          render={(value) => (value ? <DateField value={value} format="LLL" /> : null)}
        />

        <Table.Column<ISale>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => (
            <Space>
              <EditButton size="small" recordItemId={record.id} hideText />
              <EndSale sale={record} />

              {record.endedAt && (
                <Button
                  style={{ margin: 0 }}
                  onClick={async () => {
                    const response = await axios.get(`${apiUrl}/sales/${record.id}/purchases`, {
                      responseType: "blob",
                    })
                    FileDownload(response.data, `${record.project}-${record.item}_purchases.csv`)
                  }}
                >
                  Download csv
                </Button>
              )}

              <WithdrawButton sale={record} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
