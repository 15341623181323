import invariant from "tiny-invariant"
import { BigNumber } from "ethers"
import { useWeb3React } from "@web3-react/core"
import { useV1SaleContract } from "./useSaleContract.hook"
import { ISale } from "interfaces"
import { useQuery } from "react-query"

export const useBusdRemaining = (sale: ISale) => {
  const { account } = useWeb3React()
  const contract = useV1SaleContract(sale.contractAddress)

  return useQuery<BigNumber>(
    [
      {
        provider: "web3",
        variable: "busdRemaining",
        contract: sale.contractAddress,
        saleId: sale.saleId,
      },
    ],
    async () => {
      invariant(contract, "contract is undefined")
      invariant(account, "account is undefined")

      const saleInfo = await contract.saleInfo(sale.saleId)
      const remaining = saleInfo.amountBusdFunded.sub(saleInfo.amountBusdWithdrawn)
      return remaining
    },
    {
      enabled: Boolean(contract) && Boolean(account),
      staleTime: Infinity,
    }
  )
}
