import React from "react"
import { IDeal } from "interfaces"
import { useWithdraw } from "./contractMutations"
import { formatNumber } from "utils/number"
import { Button, TextField, Spin } from "@pankod/refine"
import { useBusdBalance } from "utils/contract"

export const WithdrawButton: React.FC<{ deal: IDeal }> = ({ deal }) => {
  const { mutate: withdraw, isLoading } = useWithdraw(deal.contractAddress)
  const { data: busdRemaining, isLoading: isBusdLoading } = useBusdBalance(deal.contractAddress)

  if (isBusdLoading) {
    return <Spin />
  }
  if (!deal.ended || !busdRemaining) {
    return null
  }
  if (busdRemaining.isZero()) {
    return <TextField value="BUSD withdrawn" />
  }

  return (
    <Button disabled={isLoading} loading={isLoading} onClick={() => withdraw(busdRemaining)}>
      Withdraw {formatNumber(busdRemaining.toFixed(0))} BUSD
    </Button>
  )
}
